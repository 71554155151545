<template>
  <div class="me-detail-zan">
    <label>
      <i :class="liked ? 'me-icon-zanSolid' :'me-icon-zan'" @click="handlerZanActive"></i>
      {{curCount}}
    </label>
    <div class="me-detail-zan__peoples" v-if="likePeople.length">
      <template v-for="(item, index) in likePeople">
        <!-- :item=" { name: item.userName, id: item.providerId, time: item.time }" -->
        <Avatar
          :src="item.userAvatar"
          :key="index"
          :id="item.providerId"
          v-if="index<10"
          width="40px"
          height="40px"
        />
      </template>
      <i class="me-icon-moreDian morepeople" v-if="likePeople.length > 10"></i>
    </div>
  </div>
</template>
<script>
import { getLikePeoloes } from "@/api/webApi";
import Avatar from "@/components/common/avatar";
import { componentCheckHasLogin } from "@/utils/help";
export default {
  name: "zan",
  props: {
    id: {
      type: String,
      default: ""
    },
    count: {
      type: String,
      default: ""
    },
    likeStatus: {
      type: Boolean,
      default: false
    },
    type:{
      type:Number,
      default:1
    }
  },
  components: {
    Avatar
  },
  data() {
    return {
      likePeople: [],
      liked:false,
      curCount:0
    };
  },
  created() {
    this.liked = this.likeStatus
    this.curCount = this.count
    this.handlerGetLikePeople();
  },
  methods: {
    componentCheckHasLogin,
    async handlerZanActive() {
      this.componentCheckHasLogin();
        let params = {
          userId: this.$store.state.User.userinfo.id,
          language: localStorage.getItem("langCode") || "1",
          action: !this.liked,
          type: this.type || 1
        };
        let url = `rest/home/like/${this.id}`
        let result = await this.$http({
          url,
          params,
          withCredentials: false,
          method: "post"
        }).catch(e => {});
        if(result && result.code === 201){
          this.liked = !this.liked
          if(this.liked) this.curCount ++
          else this.curCount--
          this.handlerGetLikePeople()
        }          
    },
    async handlerGetLikePeople() {
      const params = {
        id: this.id,
        language: localStorage.getItem("langCode") || "1",
        type: "1"
      };
      const result = await getLikePeoloes(params).catch(e => {});
      if (result && result.data) this.likePeople = result.data.likeList;
      this.curCount = this.likePeople.length
      this.liked = this.likePeople.some(el=>{
        return el.providerId === this.$store.state.User.userinfo.id
      })
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-detail-zan) {
  display: flex;
  flex-flow: column;
  padding: 0.9rem;
  align-items: flex-start;
  border-bottom: 6px solid #f5f5f5;

  .me-icon-zanSolid {
    color: #fe0657;
  }

  label {
    margin: 0.5rem 0;
    font-size: 16px;
    color: #999999;
    display: flex;
    align-items: center;
    width: 100%;

    i {
      font-size: 22px;
      margin-right: 0.25rem;
    }
  }

  +e(peoples) {
    display: flex;
    flex-flow: row;
    overflow-x: scroll;
    margin-top: 0.5rem;
    width: 100%;

    .morepeople {
      font-size: 48px;
      color: #e5e5e5;
    }
  }
}
</style>