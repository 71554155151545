var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "me-detail-zan" }, [
    _c("label", [
      _c("i", {
        class: _vm.liked ? "me-icon-zanSolid" : "me-icon-zan",
        on: { click: _vm.handlerZanActive }
      }),
      _vm._v("\n    " + _vm._s(_vm.curCount) + "\n  ")
    ]),
    _vm.likePeople.length
      ? _c(
          "div",
          { staticClass: "me-detail-zan__peoples" },
          [
            _vm._l(_vm.likePeople, function(item, index) {
              return [
                index < 10
                  ? _c("Avatar", {
                      key: index,
                      attrs: {
                        src: item.userAvatar,
                        id: item.providerId,
                        width: "40px",
                        height: "40px"
                      }
                    })
                  : _vm._e()
              ]
            }),
            _vm.likePeople.length > 10
              ? _c("i", { staticClass: "me-icon-moreDian morepeople" })
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }